<template>
  <div class="container-fluid" v-if="!loading">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <billing-nav :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <busy :visible="loading" />
        <div v-if="!loading">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col">
                  <h4>Add a new User to your Clinic</h4>
                </div>
              </div>

              <form @submit.prevent="updateSubscription">
                <div class="row mb-3">
                  <div class="col-8 mx-auto">
                    <div class="card bg-light">
                      <div class="card-body">
                        <div class="row mb-3 justify-content-center">
                          <div class="col-6" v-for="plan in plans">
                            <div class="card cursor-pointer" @click="selectPlan(plan)">
                              <div class="card-img-top p-2 text-center" :class="selectedPlan===plan ? 'bg-primary text-white' : 'bg-light'">
                                <i class="far fa-3x mt-2" :class="plan.user_role === 'administrator' ? 'fa-user-cog' : 'fa-user-md'"></i>
                                <p class="text-capitalize">{{ plan.user_role }}</p>
                              </div>
                              <div class="card-body">
                                <div class="card-text text-center">
                                  <p class="fw-bold m-0">£{{ plan.price }}</p>
                                  <p class="m-0"><i>({{ plan.billing_period }})</i></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col">
                            <label>First Name</label>
                            <input type="text" class="form-control" v-model="newUser.first_name" placeholder="First Name" required />
                          </div>
                          <div class="col">
                            <label>Last Name</label>
                            <input type="text" class="form-control" v-model="newUser.last_name" placeholder="Last Name" required />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <label>Email Address</label>
                            <input type="email" class="form-control" v-model="newUser.email" placeholder="Email Address" required />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col">
                            <label>Payment Method</label>
                            <select class="form-control"
                                    v-model="paymentMethod">
                              <option selected disabled :value="null">Select a Payment Method</option>
                              <option v-for="method in clinicPaymentMethods"
                                      :value="method.id">
                                {{method.card_brand.toUpperCase()}} - xxxx-xxxx-xxxx-{{method.card_last_four}} ({{method.exp_month | padMonth}}/{{method.exp_year}})
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div v-if="selectedPlan" class="row mb-1">
                  <div class="col">
                    <busy :visible="loadingInvoice" />
                    <div v-if="!loadingInvoice">
                      <upcoming-invoice :invoice="invoiceBlueprint"></upcoming-invoice>
                      <!-- <upcoming-invoice :invoice="invoiceBlueprint" :hide-date="user.clinic.billing_subscription.status !== 'trialing'"></upcoming-invoice> -->
                    </div>
                  </div>
                </div>

                <div class="row" v-if="invoiceBlueprint">
                  <div class="col text-center mb-2">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="busy || !paymentMethod || !invoiceBlueprint || !selectedPlan"
                    >
                      <busy :visible="busy" button>Processing...</busy>
                      <span v-if="!busy"><span v-if="!trialing">Make Payment & </span>Upgrade Subscription</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillingNav from "../partials/BillingNav";
import UpcomingInvoice from "./subscription-partials/UpcomingInvoice";
export default {
  props: [],
  data() {
    return {
      qrySelectedType: this.$route.query.selectedtype,
      stripe_key: null,
      stripe: null,
      loading: true,
      loadingInvoice: false,
      busy: false,
      plans: [],
      selectedPlan: null,
      paymentMethod: null,
      invoiceBlueprint: null,
      newUser: {},
      clinicPaymentMethods: []
    };
  },
  methods: {
    fetchClinicPaymentMethods() {
      return this.$axios.get(process.env.VUE_APP_API_URL + "/billing/fetch-payment-methods")
          .then(({ data }) => {
            this.clinicPaymentMethods = data;
          });
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
      this.fetchInvoiceBlueprint();
    },
    updateSubscription() {
      this.busy = true;
      this.$axios.post(process.env.VUE_APP_API_URL + '/billing/new-user', {
        plan: this.selectedPlan,
        user: this.newUser,
        paymentMethod: this.paymentMethod,
      }).then(({data}) => {
        this.$EventBus.$emit("alert", data);
        if (data.state === 'success'){
          setTimeout(() => {
            window.location = '/billing/seats';
          }, 3000);
        } else {
          this.busy = false;
        }
      });
    },
    fetchInvoiceBlueprint() {
      this.loadingInvoice = true;
      this.$axios.post(process.env.VUE_APP_API_URL + '/billing/new-user-invoice-blueprint', {
        plan: this.selectedPlan
      }).then(({data}) => {
          this.invoiceBlueprint = data.invoice;
          this.loadingInvoice = false;
        });
    },
    fetchAvailablePlans() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/billing/fetch-available-plans").then(({ data }) => {
        this.plans = data;
        if (!this.selectedPlan){
          const defaultType = this.qrySelectedType;
          this.selectPlan(this.plans.filter(function (p){
            return p.user_role == defaultType;
          })[0]);
        }
      });
    },
    fetchStripePublicKey() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/stripe-pk")
          .then(({ data }) => {
            this.stripe_key = data;
            this.stripe = Stripe(this.stripe_key);
            this.loading = false;
          });
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
    trialing() {
      return this.user.clinic.billing_subscription.status === 'trialing';
    }
  },
  mounted() {
    this.fetchClinicPaymentMethods();
    this.fetchAvailablePlans();
    this.fetchStripePublicKey();
  },
  components: {
    UpcomingInvoice,
    BillingNav,
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    padMonth(month) {
      if (parseInt(month) < 10){
        return "0" + month;
      } else {
        return month;
      }
    }
  },
};
</script>

<style>
</style>
