<template>
  <div>
    <div class="row mb-3 py-3 bg-light rounded">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div v-if="!hideDate">
              <h5 class="mb-2">Upcoming Invoice - <span>{{invoice.next_payment_attempt | formatDateUnix}}</span></h5>
              <hr />
            </div>
            <table class="table mb-0">
              <thead>
              <th scope="col" style="border-top: none">Item</th>
              <th scope="col" style="border-top: none">Description</th>
              <th scope="col" style="border-top: none">Price</th>
              </thead>
              <tr v-for="line in invoice.lines.data">
                <td>{{line.plan ? line.plan.nickname : ''}}</td>
                <td>{{line.description}}</td>
                <td>£{{(line.amount / 100).toFixed(2)}}</td>
              </tr>
              <tr v-for="discount in invoice.total_discount_amounts">
                <td>Discount</td>
                <td>Coupon Code</td>
                <td class="text-primary font-italic">-£{{ (discount.amount / 100).toFixed(2)}}</td>
              </tr>
              <tr>
                <td></td>
                <td><b class="float-end">Total</b></td>
                <td><b>£{{(invoice.amount_due / 100).toFixed(2)}}</b></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["invoice", "hideDate"],
  data() {
    return {
    }
  },
  components: {

  },
  methods: {
  },
  computed: {
  },
  filters: {
    formatDateUnix(date) {
      return moment.unix(date).format("LL")
    }
  },
  mounted() {
  }
};
</script>

<style>
</style>