<template>
  <div v-if="$can('manage billing')">
    <div class="card bg-light border-0 shadow-none h-100">
      <div class="card-body">
        <div class="nav-pills flex-column">
          <router-link to="/billing/subscription"
            class="nav-link px-2 pt-2"
            :class="path.includes('billing/subscription') ? 'active text-white' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-file-invoice"></i>
              </div>
              <div class="col-auto text-start my-auto">My Subscription</div>
            </div>
          </router-link>

          <router-link to="/billing/upgrade"
            class="nav-link px-2 pt-2"
            :class="path.includes('billing/upgrade') ? 'active text-white' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-arrow-alt-circle-up"></i>
              </div>
              <div class="col-auto text-start my-auto">Upgrade Plan</div>
            </div>
          </router-link>

          <router-link to="/billing/seats"
                  class="nav-link px-2 pt-2"
                  :class="path.includes('billing/seats') ? 'active text-white' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-users-medical"></i>
              </div>
              <div class="col-auto text-start my-auto">Clinic Practitioners</div>
            </div>
          </router-link>

          <router-link to="/billing/payment-methods"
              class="nav-link px-2 pt-2"
              :class="path.includes('billing/payment-methods') ? 'active text-white' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-credit-card"></i>
              </div>
              <div class="col-auto text-start my-auto">Payment Methods</div>
            </div>
          </router-link>

          <router-link to="/billing/history"
            class="nav-link px-2 pt-2"
            :class="path.includes('billing/history') ? 'active text-white' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-copy"></i>
              </div>
              <div class="col-auto text-start my-auto">Billing History</div>
            </div>
          </router-link>

          <router-link to="/billing/cancel"
               v-if="user.clinic.billing_subscription.billing_product.hierarchy > 0
                  && user.clinic.billing_subscription.status !== 'canceled'"
                  class="nav-link px-2 pt-2"
                  :class="path.includes('billing/cancel') ? 'active text-white' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-times-circle"></i>
              </div>
              <div class="col-auto text-start my-auto">Cancel Subscription</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "path"]
};
</script>

<style>
</style>
